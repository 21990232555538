import React from 'react'
import { CContainer, CSpinner } from '@coreui/react'

export default function SpinnerLoading({ ...props }) {
  return (
    <CContainer {...props}>
      <CSpinner color="primary" />
    </CContainer>
  )
}
